<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select
              v-model="listfilter.itemid"
              clearable
              placeholder="请选择字典分类"
              size="medium"
              @change="onSearch"
            >
              <el-option
                v-for="item in dictypeSelect"
                :key="item.Id"
                :label="item.FullName"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-input
              placeholder="字典名称"
              style="width: 300px; margin-left: 10px"
              size="medium"
              class="form-input-group"
              v-model="listfilter.name"
              @keyup.enter.native="onSearch"
            >
              <el-button
                slot="append"
                type="primary"
                icon="el-icon-search"
                @click="onSearch"
                >查询</el-button
              >
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="onAdd"
            >新增</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="编号" prop="Id"></el-table-column>
      <el-table-column label="字典名称" prop="ItemName"></el-table-column>
      <el-table-column label="字典值" prop="ItemValue"></el-table-column>
      <el-table-column label="字典项" prop="ItemType"></el-table-column>
      <el-table-column label="排序" prop="SortCode"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="180">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      :title="formtype == 'add' ? '添加字典' : '修改字典'"
      :visible.sync="dialogFormVisible"
      width="550px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="字典分类" prop="ItemId">
              <el-select
                v-model="form.ItemId"
                placeholder="请选择字典分类"
                size="small"
                style="width:100%;"
              >
                <el-option
                  v-for="item in dictypeSelect"
                  :key="item.Id"
                  :label="item.FullName"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="字典名称" prop="ItemName">
              <el-input v-model="form.ItemName" size="small"></el-input>
            </el-form-item>
            <el-form-item label="字典值" prop="ItemValue">
              <el-input v-model="form.ItemValue" size="small"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="SortCode">
              <el-input v-model="form.SortCode" size="small"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="dialogFormVisible=false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getDictypeList, getDictionaryList, delDictionary,addDictionary,editDictionary } from "@/api/system";
export default {
  data() {
    return {
      dictypeSelect: [],
      listData: [],
      tableLoading: false,
      listfilter: {
        itemid: "",
        name: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogFormVisible: false,
      formtype: "add",
      form: {},
      formRules: {
        ItemId: [
          { required: true, trigger: "blur", message: "请输入选择字典分类" },
        ],
        ItemName: [
          { required: true, trigger: "blur", message: "请输入字典名称" },
        ],
        ItemValue: [
          { required: true, trigger: "blur", message: "请输入字典值" },
        ],
        SortCode: [
          { required: true, trigger: "blur", message: "请输入分类排序" },
        ],
      },
    };
  },
  components: {},
  methods: {
    getDictypeList() {
      getDictypeList().then((resdata) => {
        if (resdata.code == 0) {
          this.dictypeSelect = resdata.data;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getDictionaryList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDictionary(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
          SortCode:100
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.formtype = "edit";
      this.dialogFormVisible = true;
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    },

    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addDictionary(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editDictionary(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getDictypeList();
    this.getListData();
  },
};
</script>